import React, { useContext, useEffect } from 'react';
import "../../Styles/ExamRecommendationForm.css";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserInfo } from '../Context/UserInfoContext';
import { Packs } from '../Context/PacksContext';
import { faAngleDown, faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import {MedicalContext} from '../Context/MedicalContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TinyTrashcanIcon from "../../Photos/TinyTrashcanIcon.png"
import Examedi from "../../Photos/Examedi.svg"
import TinyExamIcon from "../../Photos/TinyExamIcon.png"
import TinyPageIcon from "../../Photos/TinyPageIcon.png"
import TinyEyeIcon from "../../Photos/TinyEyeIcon.png"
import TinyClosedEyeIcon from "../../Photos/TinyClosedEyeIcon.png"
//import packslist from "../../../JSONS/packs.json"
//import examslist from "../../../JSONS/examsByGender.json"
import packslist from "../../../JSONS/updatedPacks.json"
import packsSponsored from "../../../JSONS/sponsoredPack.json"
import examslist from "../../../JSONS/updatedExams.json"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

function ExamRecommendationForm() {

  const Navigate = useNavigate();

  const MySwal = withReactContent(Swal)

  const {
     RelatedExams, SetRelatedExams
  } = useContext(MedicalContext);

  const {
    Phone, setPhone
  } = useContext(UserInfo);

  const {
    BasketPacks, SetBasketPacks, ExamsPerProduct, SetExamsPerProduct, SetAdded, 
    ExamediSelected, SetExamediSelected, ExamediRegion, SetExamediRegion, ExamediCommune, SetExamediCommune, ExamediAllowed, SetExamediAllowed, ExamediWrong
  } = useContext(Packs);

  const [CurrentExamsForOrder,setCurrentExamsForOrder] = useState([])
  //const Recom = RelatedExams.length === 0 & UnrelatedExams.length === 0 ? 0 : RelatedExams.length !== 0 & UnrelatedExams.length !== 0 ? 2 : 1

  const [ExamDetails, setExamDetails] = useState(false);
  const [OpenBlock, setOpenBlock] = useState(-1);
  const [shown, setShown] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  var pages = Math.ceil(CurrentExamsForOrder.length / 3)
  const [numberOfBlocks, setNumberOfBlocks] = useState(0);
  const [examsShown, setExamsShown] = useState(Array.from([0, 1, 2]));
  const [packOne, setPackOne] = useState([]);
  const [packOneDer, setPackOneDer] = useState([]);
  const [OneTime, setOneTime] = useState(true);
  const [ExamediFonasa, setExamediFonasa] = useState("");
  const [RecalcFonasa, setRecalcFonasa] = useState(false);

  const communeRM = ["Calera De Tango","Cerrillos","Cerro Navia","Colina","Conchali","El Bosque","El Monte","Estación Central","Huechuraba","Independencia","Isla De Maipo","La Cisterna","La Florida","La Granja","La Pintana","La Reina","Las Condes","Lo Barnechea","Lo Prado","Maipú","Macul","Ñuñoa","Padre Hurtado","Pedro Aguirre Cerda","Peñaflor","Peñalolén","Pirque","Providencia","Pudahuel","Puente Alto","Quilicura","Quinta Normal","Recoleta","Renca","San Bernardo","San Joaquií","San José de Miapo","San Miguel","San Ramón","Santiago","Talagante","Vitacura"]
  const communeAN = ["Antofagasta"]
  const communeCO = ["Coquimbo","La Serena"]
  const communeVA = ["Concón","Limache","Placilla","Quillota","Quilpué"]
  const communeBI = ["Chiguayante","Concepción","Coronel","Hualpén","Hualqui","Penco","San Pedro de la Paz","Talcahuano","Tomé"]

  useEffect(() => {
    if(document.getElementById('FullForm') && OneTime) {
      if(ExamediSelected) {
        document.getElementById('Examedi').checked = true
        document.getElementById("ExamediForm").className = document.getElementById("ExamediForm").className.replace(" ExamRecommendationFormInnerDiv2BlockHidden", "");
        SetExamediSelected(true)
        if(document.getElementById('RegionSelect')) document.getElementById('RegionSelect').value=ExamediRegion;
        if(document.getElementById('CommuneSelect')) document.getElementById('CommuneSelect').value=ExamediCommune;
        if(document.getElementById('ExamediAllowed')) document.getElementById('ExamediAllowed').checked= ExamediAllowed ? true : false;
      }
      CalculateFonasa()
      setOneTime(false)
    }
    if(RecalcFonasa) {
      CalculateFonasa()
      setRecalcFonasa(false)
    }
  })

  useEffect(() => {
    if(numberOfBlocks !== BasketPacks.length & OpenBlock !== -1) {
      setExamDetails(false)
      let tmp6 = document.getElementsByClassName("ExamRecommendationFormInnerBoxLowerLines")
      let tmp7 = document.getElementsByClassName("ExamRecommendationFormInnerBoxLowerLinesPageDots")
      //let tmp8 = document.getElementsByClassName("ExamRecommendationFormInnerBoxRiskLines")
      for(let i=0;i<tmp6.length;i++) {
        if(!tmp6[i].classList.contains("ExamRecommendationFormHidden")) {
          tmp6[i].className += " ExamRecommendationFormHidden"
          tmp7[i].className += " ExamRecommendationFormHidden"
          //tmp8[i].className += " ExamRecommendationFormHidden"
        }
      }
      setCurrentPage(1)
      if(OpenBlock <= BasketPacks.length) {
        let tmp4 = document.getElementById('pageDot'.concat(currentPage))
        tmp4.className = tmp4.className.replace(" ActiveDot", "");
        let tmp5 = document.getElementById('pageDot1')
        tmp5.className += " ActiveDot"
      }
      setRecalcFonasa(true)
    }
  })

  function CalculateFonasa() {
    let exams = []
    for (const property in ExamsPerProduct) {
      exams.push(...ExamsPerProduct[property]['exams']);
    }
    exams.push(...RelatedExams)
    let uniques = [...new Set(exams)]

    let totalexams = uniques.length
    let accounted = 0
    let fonprice = 0
    for(let i=0;i<uniques.length;i++) {
      if(examslist[exams[i]].fonasa) {
        fonprice += examslist[exams[i]].fonasa
        accounted += 1
      }
    }
    setExamediFonasa({total: totalexams, acc: accounted, price: fonprice})
  }

  function ChangeExamPage(step, length) {
    let tmp = document.getElementById('pageDot'.concat(currentPage))
    //console.log(tmp,currentPage)
    tmp.className = tmp.className.replace(" ActiveDot", "");
    let tmp2 = document.getElementById('pageDot'.concat(currentPage + (step/3)))
    //console.log(tmp2,currentPage + (step/3))
    tmp2.className += " ActiveDot"

    setCurrentPage(currentPage + (step/3))
    //console.log(shown,length)
    if(shown === length - 1 & step > 0) {
      setExamsShown(Array.from([0], (x) => x + shown + step))
    } else if (shown == length - 2 & step > 0) {
      setExamsShown(Array.from([0, 1], (x) => x + shown + step))
    } else setExamsShown(Array.from([0, 1, 2], (x) => x + shown + step))
    setShown(shown + step)

    //console.log(shown,examsShown,currentPage)
  }

  function ShowDetails(block,id1,id3,exams) {
    //console.log(block,OpenBlock,ExamsPerProduct[BasketPacks[block-1]].exams.map(a => examslist[a]),exams)
    if(exams.length === 2) {
      setExamsShown(Array.from([0, 1]))
    } else if(exams.length === 1){
      setExamsShown(Array.from([0]))
    } else {
      setExamsShown(Array.from([0, 1, 2]))
    }
    let tmp = document.getElementById(id1)
    let tmp3 = document.getElementById(id3)
    let tmp6 = document.getElementsByClassName("ExamRecommendationFormInnerBoxLowerLines")
    let tmp7 = document.getElementsByClassName("ExamRecommendationFormInnerBoxLowerLinesPageDots")
    if(ExamDetails & block === OpenBlock) {
      setExamDetails(false)
      if(tmp) tmp.className += " ExamRecommendationFormHidden"
      if(tmp3) tmp3.className += " ExamRecommendationFormHidden"
      let tmp4 = document.getElementById('pageDot'.concat(currentPage))
      if(tmp4) tmp4.className = tmp4.className.replace(" ActiveDot", "");
      setCurrentPage(1)
      let tmp5 = document.getElementById('pageDot1')
      if(tmp5) tmp5.className += " ActiveDot"

    } else {
      setExamDetails(true)
      for(let i=0;i<tmp6.length;i++) {
        if(!tmp6[i].classList.contains("ExamRecommendationFormHidden")) {
          if(tmp6[i]) tmp6[i].className += " ExamRecommendationFormHidden"
          if(tmp7[i]) tmp7[i].className += " ExamRecommendationFormHidden"
        }
      }
      setNumberOfBlocks(BasketPacks.length)
      if(block !== 0) {
        setPackOne(ExamsPerProduct[BasketPacks[block-1]].exams.filter(b => examslist[b].emission !== "Derivaciones" && examslist[b].emission !== "").map(a => examslist[a]))
        setPackOneDer(ExamsPerProduct[BasketPacks[block-1]].exams.filter(b => examslist[b].emission === "Derivaciones").map(a => examslist[a]))
      } else {
        setPackOne(RelatedExams.filter(b => examslist[b].emission !== "Derivaciones" && examslist[b].emission !== "").map(a => examslist[a]))
        setPackOneDer(RelatedExams.filter(b => examslist[b].emission === "Derivaciones").map(a => examslist[a]))
      }
      setOpenBlock(block)
      tmp.className = tmp.className.replace(" ExamRecommendationFormHidden", "");
      if(tmp3) tmp3.className = tmp3.className.replace(" ExamRecommendationFormHidden", "");
      setCurrentPage(1)
      setShown(0)
    }
  }

  function EliminateBlock(pack) {
    if(pack === "Recommended") {
      SetRelatedExams([])
    } else {
      let listOfPacks = BasketPacks
      let examsOfPacks = ExamsPerProduct
      let index = listOfPacks.indexOf(pack);
      if (index > -1) { 
          listOfPacks.splice(index, 1); 
      }
      delete examsOfPacks[pack]
      SetBasketPacks([...listOfPacks])
      SetExamsPerProduct({...examsOfPacks})
      SetAdded(false)
      setRecalcFonasa(true)
    }
  }

  function showExamedi() {
    let tmp = document.getElementById("Examedi")
    let tmp2 = document.getElementById("ExamediForm")
    if(tmp.checked) {
      tmp2.className = tmp2.className.replace(" ExamRecommendationFormInnerDiv2BlockHidden", "");
      SetExamediSelected(true)
      setOneTime(true)
    } else {
      tmp2.className += " ExamRecommendationFormInnerDiv2BlockHidden"
      SetExamediSelected(false)
    }
  }

  return (
    <>
    {RelatedExams.length === 0 & BasketPacks.length === 0 ?
    <div className="ExamRecommendationFormDivEmpty">
      <div className="ExamRecommendationFormEmpty">Tu Orden Médica está vacía</div>
      <div className="ExamRecommendationFormEmptyInfo">Puedes revisar nuestros exámenes disponibles aquí:</div>
      <div className="ExamRecommendationFormEmptyButton" onClick={() => Navigate("/packs-de-examenes")}>Ver Exámenes</div>
      <div className="ExamRecommendationFormEmptyInfo">O responde unas preguntas cortas y consigue una orden médica de nuestro recomendador inteligente:</div>
      <div className="ExamRecommendationFormEmptyButton" onClick={() => Navigate("/recomendador/paso-1")}>Obtén tu recomendación de exámenes</div>
    </div>
    :
    <div className="ExamRecommendationFormDiv" id="FullForm">
      <div className="ExamRecommendationFormInnerDiv1">
        <div className="ExamRecommendationFormUpperTitleFlex">
          <div className="ExamRecommendationFormUpperTitleText">Producto</div>
          <div className='ExamRecommendationFormUpperTitleRightSide'>Precio</div>
        </div>
      <div className="ExamRecommendationFormDiv">
        {RelatedExams.length === 0 ?
        null
        :
        <div>
        <div className="ExamRecommendationFormInnerBox">
          <div className="ExamRecommendationFormInnerBoxUpperRow">
            <div className="ExamRecommendationFormInnerBoxUpperRowText">Exámenes Recomendados</div>
        {/*    <div className="ExamRecommendationFormInnerBoxUpperRowIcons">
              <img className="ExamRecommendationFormInnerBoxUpperRowExamIcon" alt='' src={TinyExamIcon} />
              <img className="ExamRecommendationFormInnerBoxUpperRowPageIcon" alt='' src={TinyPageIcon} />
            </div> */}
            <div className="ExamRecommendationFormInnerBoxUpperRowRightSide">
              {ExamDetails & OpenBlock === 0 ? 
              <div className="ExamRecommendationFormInnerBoxShowDetails" onClick={() => ShowDetails(0,"PackDetails".concat(0),"PackDots".concat(0),RelatedExams.filter(obj => examslist[obj].emission !== "Derivaciones" && examslist[obj].emission !== ""))}>
                <div className="ExamRecommendationFormInnerBoxShowDetailsText">Ocultar Detalle</div>
                <img className="ExamRecommendationFormInnerBoxEyeIcon" alt='' src={TinyEyeIcon} />
              </div>
              :
              <div className="ExamRecommendationFormInnerBoxShowDetails" onClick={() => ShowDetails(0,"PackDetails".concat(0),"PackDots".concat(0),RelatedExams.filter(obj => examslist[obj].emission !== "Derivaciones" && examslist[obj].emission !== ""))}>
                <div className="ExamRecommendationFormInnerBoxShowDetailsText">Mostrar Detalle</div>
                <img className="ExamRecommendationFormInnerBoxEyeIcon" alt='' src={TinyClosedEyeIcon} />
              </div> }
              <div className="ExamRecommendationFormInnerBoxUpperRowPackPrice">$ 990</div>
              <img className="ExamRecommendationFormInnerBoxUpperRowTrashcanIcon" alt='' src={TinyTrashcanIcon} 
              onClick={() => MySwal.fire({
                title: "Desea eliminar el pack de su Orden Médica?",
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: "Eliminar",
                confirmButtonColor: "#6633FF"
                }).then((result) => {
                  /* Read more about isConfirmed, isDenied below */
                  if (result.isConfirmed) {
                    EliminateBlock("Recommended")
                    MySwal.fire({
                      icon: "success",
                      iconColor: "#6633FF",
                      title: "El pack fue eliminado con éxito",
                      showConfirmButton: false,
                      timer: 1500
                    })
                  }
                })}/>
            </div>
            
          </div>


          <div className="ExamRecommendationFormInnerBoxHorizontalLine"></div>


          <div className="ExamRecommendationFormInnerBoxLowerLines ExamRecommendationFormHidden" id={"PackDetails".concat(0)}>
          {packOne.length === 0 ?
            null :
            <>
            <div className="ExamRecommendationFormInnerBoxLowerLinesFlex">
              <div className="ExamRecommendationFormInnerBoxLowerLinesLeftFlex">
                <img className="ExamRecommendationFormInnerBoxUpperRowExamIcon" alt='' src={TinyExamIcon} />
                <img className="ExamRecommendationFormInnerBoxUpperRowPageIcon" alt='' src={TinyPageIcon} />
                <div className="ExamRecommendationFormInnerBoxLowerLinesLeftText">Exámenes:</div>
              </div>
              {/* <div>({ExamsPerProduct[BasketPacks[IndividualPack]].exams.length})</div> */}
            </div>
            <div className="ExamRecommendationFormInnerBoxExamLinesFlex">
              {shown === 0 ?
              null
              :
              <div className="ExamRecommendationFormInnerBoxExamLineArrowBlock" onClick={() => ChangeExamPage(-3,packOne.length-3)}>
                <FontAwesomeIcon 
                  icon={faArrowRightLong} 
                  className='ExamRecommendationFormInnerBoxExamLineLeftArrow text-end fa-rotate-180'
                />
              </div>
  }
              <div className="ExamRecommendationFormInnerBoxExamLineExamBlockContainer">
              {examsShown.map((exam) => (
              
                <div className="ExamRecommendationFormInnerBoxExamLineExamBlock" key={exam}>
                  {OpenBlock === 0 ? 
                  <div className="ExamRecommendationFormInnerBoxExamLineExamNameFlex">
                    <img className="ExamRecommendationFormInnerBoxExamLineExamIcon" alt='' src={TinyExamIcon} />
                    <div className="ExamRecommendationFormInnerBoxExamLineExamName">{packOne[exam].name}</div>
                  </div>
                  : null}
                  {packOne[exam] ?
                  <div className="ExamRecommendationFormInnerBoxExamLineExamDescription">{packOne[exam].description}</div>
                  : null }
                </div>
              
              ))}
              </div>
              {shown >= packOne.length-3 ?
              null:
              <div className="ExamRecommendationFormInnerBoxExamLineArrowBlock" onClick={() => ChangeExamPage(3,packOne.length-3)}>
                <FontAwesomeIcon 
                  icon={faArrowRightLong} 
                  className='ExamRecommendationFormInnerBoxExamLineRightArrow text-end'
                />
              </div>
  }
            </div>
            <div className="ExamRecommendationFormInnerBoxLowerLinesPageDots" id={"PackDots".concat(0)}>
            {OpenBlock === 0 ?
            <>
              <div className="ExamRecommendationFormInnerBoxLowerLinesIndividualPageDot ActiveDot" id="pageDot1"></div>
              {[...Array(Math.ceil(packOne.length / 3)-1).keys()].map((dots) => (
              <div className="ExamRecommendationFormInnerBoxLowerLinesIndividualPageDot" key={'pageDot'.concat(dots+2)} id={'pageDot'.concat(dots+2)}></div>
              ))}
              </>
              : null}
              
            </div>
            </>
            }
            {packOneDer.length === 0 ?
            null :
            <>
            <div className="ExamRecommendationFormInnerBoxLowerLinesFlex">
              <div className="ExamRecommendationFormInnerBoxLowerLinesLeftFlex">
                <img className="ExamRecommendationFormInnerBoxUpperRowExamIcon" alt='' src={TinyExamIcon} />
                <img className="ExamRecommendationFormInnerBoxUpperRowPageIcon" alt='' src={TinyPageIcon} />
                <div className="ExamRecommendationFormInnerBoxLowerLinesLeftText">Derivaciones:</div>
              </div>
            </div>
            <div className="ExamRecommendationFormInnerBoxRiskLines">

              {packOneDer.map((exam) => (
              
              <div className="ExamRecommendationFormInnerBoxExamLineExamBlock" key={exam}>
                <div className="ExamRecommendationFormInnerBoxExamLineExamNameFlex">
                  <img className="ExamRecommendationFormInnerBoxExamLineExamIcon" alt='' src={TinyExamIcon} />
                  <div className="ExamRecommendationFormInnerBoxExamLineExamName">{exam.name}</div>
                </div>
              </div>
            
            ))}

            </div>
            </>
              }
          </div>
        </div>
        </div>
  }
      </div>



      {[...Array(BasketPacks.length).keys()].map((IndividualPack) => (
        <div key={IndividualPack}>
        <div className="ExamRecommendationFormInnerBox">
          <div className="ExamRecommendationFormInnerBoxUpperRow">
            <div className="ExamRecommendationFormInnerBoxUpperRowText">{packslist[BasketPacks[IndividualPack]] ? packslist[BasketPacks[IndividualPack]].name : packsSponsored[BasketPacks[IndividualPack]].name}</div>
        {/*    <div className="ExamRecommendationFormInnerBoxUpperRowIcons">
              <img className="ExamRecommendationFormInnerBoxUpperRowExamIcon" alt='' src={TinyExamIcon} />
              <img className="ExamRecommendationFormInnerBoxUpperRowPageIcon" alt='' src={TinyPageIcon} />
            </div> */}
            <div className="ExamRecommendationFormInnerBoxUpperRowRightSide">
              {ExamDetails & OpenBlock === IndividualPack+1 ? 
              <div className="ExamRecommendationFormInnerBoxShowDetails" onClick={() => ShowDetails(IndividualPack+1,"PackDetails".concat(IndividualPack+1),"PackDots".concat(IndividualPack),ExamsPerProduct[BasketPacks[IndividualPack]].exams.filter(obj => examslist[obj].emission !== "Derivaciones" && examslist[obj].emission !== ""))}>
                <div className="ExamRecommendationFormInnerBoxShowDetailsText">Ocultar Detalle</div>
                <img className="ExamRecommendationFormInnerBoxEyeIcon" alt='' src={TinyEyeIcon} />
              </div>
              :
              <div className="ExamRecommendationFormInnerBoxShowDetails" onClick={() => ShowDetails(IndividualPack+1,"PackDetails".concat(IndividualPack+1),"PackDots".concat(IndividualPack),ExamsPerProduct[BasketPacks[IndividualPack]].exams.filter(obj => examslist[obj].emission !== "Derivaciones" && examslist[obj].emission !== ""))}>
                <div className="ExamRecommendationFormInnerBoxShowDetailsText">Mostrar Detalle</div>
                <img className="ExamRecommendationFormInnerBoxEyeIcon" alt='' src={TinyClosedEyeIcon} />
              </div> }
              {ExamsPerProduct[BasketPacks[IndividualPack]].price === 0 ?
              <div className="ExamRecommendationFormInnerBoxUpperRowPackPrice">Gratis</div>
              :
              <div className="ExamRecommendationFormInnerBoxUpperRowPackPrice">$ {ExamsPerProduct[BasketPacks[IndividualPack]].price}</div>
  }
              <img className="ExamRecommendationFormInnerBoxUpperRowTrashcanIcon" alt='' src={TinyTrashcanIcon} 
              onClick={() => MySwal.fire({
                title: "Desea eliminar el pack de su Orden Médica?",
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: "Eliminar",
                confirmButtonColor: "#6633FF"
                }).then((result) => {
                  /* Read more about isConfirmed, isDenied below */
                  if (result.isConfirmed) {
                    EliminateBlock(BasketPacks[IndividualPack])
                    MySwal.fire({
                      icon: "success",
                      iconColor: "#6633FF",
                      title: "El pack fue eliminado con éxito",
                      showConfirmButton: false,
                      timer: 1500
                    })
                  }
                })}/>
            </div>
            
          </div>
          <div className="ExamRecommendationFormInnerBoxHorizontalLine"></div>
          <div className="ExamRecommendationFormInnerBoxLowerLines ExamRecommendationFormHidden" id={"PackDetails".concat(IndividualPack+1)}>
            {packOne.length === 0 ?
            null :
            <>
            <div className="ExamRecommendationFormInnerBoxLowerLinesFlex">
              <div className="ExamRecommendationFormInnerBoxLowerLinesLeftFlex">
                <img className="ExamRecommendationFormInnerBoxUpperRowExamIcon" alt='' src={TinyExamIcon} />
                <img className="ExamRecommendationFormInnerBoxUpperRowPageIcon" alt='' src={TinyPageIcon} />
                <div className="ExamRecommendationFormInnerBoxLowerLinesLeftText">Exámenes:</div>
              </div>
              {/* <div>({ExamsPerProduct[BasketPacks[IndividualPack]].exams.length})</div> */}
            </div>
            <div className="ExamRecommendationFormInnerBoxExamLinesFlex">
              {shown === 0 ?
              null
              :
              <div className="ExamRecommendationFormInnerBoxExamLineArrowBlock" onClick={() => ChangeExamPage(-3,packOne.length-3)}>
                <FontAwesomeIcon 
                  icon={faArrowRightLong} 
                  className='ExamRecommendationFormInnerBoxExamLineLeftArrow text-end fa-rotate-180'
                />
              </div>
  }
              <div className="ExamRecommendationFormInnerBoxExamLineExamBlockContainer">
              {examsShown.map((exam) => (
              
                <div className="ExamRecommendationFormInnerBoxExamLineExamBlock" key={exam}>
                  {OpenBlock === IndividualPack+1 ? 
                  <div className="ExamRecommendationFormInnerBoxExamLineExamNameFlex">
                    <img className="ExamRecommendationFormInnerBoxExamLineExamIcon" alt='' src={TinyExamIcon} />
                    <div className="ExamRecommendationFormInnerBoxExamLineExamName">{packOne[exam].name}</div>
                  </div>
                  : null}
                  {packOne[exam] ?
                  <div className="ExamRecommendationFormInnerBoxExamLineExamDescription">{packOne[exam].description}</div>
                  : null }
                </div>
              
              ))}
              </div>
              {shown >= packOne.length-3 ?
              null:
              <div className="ExamRecommendationFormInnerBoxExamLineArrowBlock" onClick={() => ChangeExamPage(3,packOne.length-3)}>
                <FontAwesomeIcon 
                  icon={faArrowRightLong} 
                  className='ExamRecommendationFormInnerBoxExamLineRightArrow text-end'
                />
              </div>
  }
            </div>
            <div className="ExamRecommendationFormInnerBoxLowerLinesPageDots" id={"PackDots".concat(IndividualPack)}>
            {OpenBlock === IndividualPack+1 ?
            <>
              <div className="ExamRecommendationFormInnerBoxLowerLinesIndividualPageDot ActiveDot" id="pageDot1"></div>
              {[...Array(Math.ceil(packOne.length / 3)-1).keys()].map((dots) => (
              <div className="ExamRecommendationFormInnerBoxLowerLinesIndividualPageDot" key={'pageDot'.concat(dots+2)} id={'pageDot'.concat(dots+2)}></div>
              ))}
              </>
              : null}
              
            </div>
            </>
            }
            {packOneDer.length === 0 ?
            null :
            <>
            <div className="ExamRecommendationFormInnerBoxLowerLinesFlex">
              <div className="ExamRecommendationFormInnerBoxLowerLinesLeftFlex">
                <img className="ExamRecommendationFormInnerBoxUpperRowExamIcon" alt='' src={TinyExamIcon} />
                <img className="ExamRecommendationFormInnerBoxUpperRowPageIcon" alt='' src={TinyPageIcon} />
                <div className="ExamRecommendationFormInnerBoxLowerLinesLeftText">Derivaciones:</div>
              </div>
            </div>
            <div className="ExamRecommendationFormInnerBoxRiskLines">

              {packOneDer.map((exam) => (
              
              <div className="ExamRecommendationFormInnerBoxExamLineExamBlock" key={exam}>
                <div className="ExamRecommendationFormInnerBoxExamLineExamNameFlex">
                  <img className="ExamRecommendationFormInnerBoxExamLineExamIcon" alt='' src={TinyExamIcon} />
                  <div className="ExamRecommendationFormInnerBoxExamLineExamName">{exam.name}</div>
                </div>
              </div>
            
            ))}

            </div>
            </>
              }
          </div>
        </div>
      </div>
  ))}
          </div>
            <div className="ExamRecommendationFormInnerDiv2">
              <div className="ExamRecommendationFormInnerDiv2Flex">
                <img className="ExamRecommendationFormInnerDiv2ExamediLogo" alt='' src={Examedi} />
                <div className="ExamRecommendationFormInnerDiv2Title">Con Examedi podrás realizar los exámenes en la comodidad de tu hogar o incluso oficina.</div>
              </div>
              <div className="ExamRecommendationFormInnerDiv2Subtitle">Al finalizar la compra Examedi te contactará para presentar una cotización.</div>
              <div className="ExamRecommendationFormInnerDiv2Flex2">
                <input type="checkbox" name="useExamedi" className="ExamRecommendationFormInnerDiv2Checkbox" id="Examedi" onClick={() => showExamedi()}/>
                <div className="ExamRecommendationFormInnerDiv2CheckboxText">Quiero que Examedi me envíe cotización. </div>
              </div>
              <div className="ExamRecommendationFormInnerDiv2LowerBlock ExamRecommendationFormInnerDiv2BlockHidden" id='ExamediForm'>
                <div className="ExamRecommendationFormInnerDiv2LowerBlockInstructions">Seleccione la región y comuna donde se realizaría el servicio (hogar u oficina). Solo se muestran las comunas en cobertura, si no encuentra su comuna de momento no puede optar al servicio. En tal caso, por favor des-seleccionar el checkbox arriba de este texto.</div>
                <div className="ExamRecommendationFormInnerDiv2LowerBlockSelector">
                  <select defaultValue={"Seleccione Región"} className='ExamRecommendationFormInnerDiv2LowerBlockSelectorTile rounded-2' id="RegionSelect" 
                  style={{ border: ExamediWrong && ExamediRegion === "" ? "red solid 1px" : "#CCCCFF solid 2px" }} onChange={(e) => {SetExamediRegion(e.target.value); SetExamediCommune("")}}>
                    <option key="Region0" value="">Seleccione Región</option>
                    <option key="Region1" value="RM">Región Metropolitana</option>
                    <option key="Region2" value="AN">Antofagasta</option>
                    <option key="Region3" value="CO">Coquimbo</option>
                    <option key="Region4" value="VA">Valparaíso</option>
                    <option key="Region5" value="BI">BioBío</option>
                  </select>
                </div>
                {ExamediRegion === "RM" ?
                <div className="ExamRecommendationFormInnerDiv2LowerBlockSelector">
                  <select defaultValue={"Seleccione Comuna"} className='ExamRecommendationFormInnerDiv2LowerBlockSelectorTile rounded-2' id="CommuneSelect" 
                  style={{ border: ExamediWrong && ExamediCommune === "" ? "red solid 1px" : "#CCCCFF solid 2px" }} onChange={(e) => SetExamediCommune(e.target.value)}>
                    <option key="Commune0" value="">Seleccione Comuna</option>
                    {communeRM.map((commune) => (
                      <option key={commune} value={commune}>{commune}</option>
                    ))}
                  </select>
                </div>
                : ExamediRegion === "AN" ?
                <div className="ExamRecommendationFormInnerDiv2LowerBlockSelector">
                  <select defaultValue={"Seleccione Comuna"} className='ExamRecommendationFormInnerDiv2LowerBlockSelectorTile rounded-2' id="CommuneSelect" 
                  style={{ border: ExamediWrong && ExamediCommune === "" ? "red solid 1px" : "#CCCCFF solid 2px" }} onChange={(e) => SetExamediCommune(e.target.value)}>
                    <option key="Commune0" value="">Seleccione Comuna</option>
                    {communeAN.map((commune) => (
                      <option key={commune} value={commune}>{commune}</option>
                    ))}
                  </select>
                </div>
                : ExamediRegion === "CO" ?
                <div className="ExamRecommendationFormInnerDiv2LowerBlockSelector">
                  <select defaultValue={"Seleccione Comuna"} className='ExamRecommendationFormInnerDiv2LowerBlockSelectorTile rounded-2' id="CommuneSelect" 
                  style={{ border: ExamediWrong && ExamediCommune === "" ? "red solid 1px" : "#CCCCFF solid 2px" }} onChange={(e) => SetExamediCommune(e.target.value)}>
                    <option key="Commune0" value="">Seleccione Comuna</option>
                    {communeCO.map((commune) => (
                      <option key={commune} value={commune}>{commune}</option>
                    ))}
                  </select>
                </div>
                : ExamediRegion === "VA" ?
                <div className="ExamRecommendationFormInnerDiv2LowerBlockSelector">
                  <select defaultValue={"Seleccione Comuna"} className='ExamRecommendationFormInnerDiv2LowerBlockSelectorTile rounded-2' id="CommuneSelect" 
                  style={{ border: ExamediWrong && ExamediCommune === "" ? "red solid 1px" : "#CCCCFF solid 2px" }} onChange={(e) => SetExamediCommune(e.target.value)}>
                    <option key="Commune0" value="">Seleccione Comuna</option>
                    {communeVA.map((commune) => (
                      <option key={commune} value={commune}>{commune}</option>
                    ))}
                  </select>
                </div>
                : ExamediRegion === "BI" ?
                <div className="ExamRecommendationFormInnerDiv2LowerBlockSelector">
                  <select defaultValue={"Seleccione Comuna"} className='ExamRecommendationFormInnerDiv2LowerBlockSelectorTile rounded-2' id="CommuneSelect" 
                  style={{ border: ExamediWrong && ExamediCommune === "" ? "red solid 1px" : "#CCCCFF solid 2px" }} onChange={(e) => SetExamediCommune(e.target.value)}>

                    <option key="Commune0" value="">Seleccione Comuna</option>
                    {communeBI.map((commune) => (
                      <option key={commune} value={commune}>{commune}</option>
                    ))}
                  </select>
                </div>
                : null}
                {ExamediCommune !== "" ?
                <>
                  <div className="ExamRecommendationFormInnerDiv2LowerBlockInputData">
                    <div className="ExamRecommendationFormInnerDiv2LowerBlockInputNumber">+56</div>
                    <input
                      value={Phone}
                      className="ExamRecommendationFormInnerDiv2LowerBlockInput"
                      style={{ border: ExamediWrong && Phone.length < 9 || Phone.length > 11 ? "red solid 1px" : "#CCCCFF solid 2px" }}
                      id="YMCPhone"
                      maxLength={20}
                      type="tel"
                      onChange={(e) => setPhone(e.target.value)}
                      placeholder="Ingresa tu celular (9 8765 4321)" />
                  </div>
                  <div className="ExamRecommendationFormInnerDiv2Flex2">
                    <input type="checkbox" name="acceptTOS" className="ExamRecommendationFormInnerDiv2Checkbox" id="ExamediAllowed" defaultChecked={ExamediAllowed ? true : false} onClick={() => SetExamediAllowed(!ExamediAllowed)}/>
                    <div className="ExamRecommendationFormInnerDiv2CheckboxText">Autorizo a Yo Me Controlo (YMC) que comparta mi Nombre, Celular y exámenes solicitados con Examedi. </div>
                  </div>
                  {ExamediFonasa.length !== 0 ?
                  <>
                    <div className="ExamRecommendationFormInnerDivPriceFlex">
                      <div className="ExamRecommendationFormInnerDivPriceFlexText">Bonificación aprox. con Fonasa:</div>
                      <div className="ExamRecommendationFormInnerDivPriceFlexText2">${ExamediFonasa.price}</div>
                    </div>
                    <div className="ExamRecommendationFormInnerDivPriceFlexLowerText">*La cifra anterior incluye {ExamediFonasa.acc}/{ExamediFonasa.total} de tus exámenes solicitados.</div>
                  </>
                    : null
                  }
                </>
                : null}
                {ExamediWrong ?
                  <>
                    {ExamediRegion === "" ?
                    <div className='ExamRecommendationBasketTOSAlert'>Por favor completar los campos faltantes o des-seleccionar la opción Examedi.
                    <br/>- Seleccione su región.</div>
                    : ExamediCommune === "" ?
                    <div className='ExamRecommendationBasketTOSAlert'>Por favor completar los campos faltantes o des-seleccionar la opción Examedi.
                    <br/>- Seleccione su comuna.</div>
                    : Phone.length < 9 || Phone.length > 11 ?
                    <div className='ExamRecommendationBasketTOSAlert'>Por favor completar los campos faltantes o des-seleccionar la opción Examedi.
                    <br/>- Ingrese un número de teléfono válido.</div>
                    : !ExamediAllowed ?
                    <div className='ExamRecommendationBasketTOSAlert'>Por favor completar los campos faltantes o des-seleccionar la opción Examedi.
                    <br/>- Seleccione el último checkbox para autorizar que Examedi lo pueda contactar.</div>
                    : 
                    null}
                  </>
                  : null}
              </div>
            </div>
          </div>
        }
    </>
  );
}

export default ExamRecommendationForm